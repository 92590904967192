import React, { FC } from 'react';

import './AnimatedImage.scss';

type AnimatedImageProps = {
  imageUrl: string;
  imageAlt: string;
  isActive?: boolean;
};

const AnimatedImage: FC<AnimatedImageProps> = (props) => {
  const { imageUrl, imageAlt, isActive } = props;

  return (
    <div className="animated-img__wrapper">
      <div
        className={`animated-img__background animated slideOutUp ${
          isActive ? 'animation-play' : ''
        }`}
      />
      <img className="animated-img__image" src={imageUrl} alt={imageAlt} loading="lazy" />
    </div>
  );
};

export default AnimatedImage;
